.small-dark {
}

.blog-container {
  border-radius: 10px;
  color: #586069;
  box-shadow: 0 0 36px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 150px;
}

.blog-title {
  color: #262626;
  font-size: 1em;
  line-height: 20px;
  font-weight: 800;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-top: 1em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.small {
  font-size: 80%;
  font-weight: 400;
  line-height: 1.2em;
  color: #666666;
  /* padding: 0px 20px; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-align: left;
  white-space: normal;   /* IE6 needs any width */
  overflow: hidden;              /* "overflow" value must be different from  visible"*/
  -o-text-overflow: ellipsis;    /* Opera < 11*/
  text-overflow:    ellipsis;
  margin-bottom: 30px;
  margin-top: 0.5em;
}

.blog-card {
  display: inline-block;
  vertical-align: top;
  position: relative;
  background-color: white !important;
  border-radius: 4px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  float: left;
  white-space: initial;
}

.blog-card:hover {
  background-color: #e0e0e0 !important;
}

.blog-card-shadow {
}

.img {
  display: inline-block;
  height: 100%;
  float: left;
  height: 100%;
  width: 20%;
  max-width: 300px;
  object-fit: cover;
  margin-right: 20px;
}
